import React from "react";
import "./header.css";
import { Container } from "@mui/material";
import Logo from "../../assets/svg/logo.svg";
import Grid from "@mui/material/Grid2";
import Button from "../Button";
import SignupForm from "../../components/dialog/Signup";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const [signupModal, setSignupModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirectSection = (e) => {
    e.preventDefault();
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      const yOffset = -70; // Adjusts for the header offset
      const yPosition =
        pricingSection.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

  return (
    <>
      <header className="header" id="header">
        <Container>
          <Grid container spacing={2}>
            <Grid size={8} className="header-left">
              <div className="logo">
                <a href="#">
                  <img src={Logo} alt="Logo" />
                </a>
              </div>
              <nav className="nav-links">
                <a href="/#pricing" onClick={(e) => handleRedirectSection(e)}>
                  Pricing
                </a>
                <a href="#">Blogs</a>
              </nav>
            </Grid>
            <Grid size={4} className="header-right">
              <ul>
                <li>
                  <a href="#" className="">
                    Contact Sales
                  </a>
                </li>
                <li>
                  <Button
                    buttonText={"Sign Up"}
                    className={"custom-btn"}
                    handleClick={() => setSignupModal(true)}
                  />
                  {/* <button type="button" className="btn custom-btn">
                    Sign Up
                  </button> */}
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </header>
      {signupModal && (
        <SignupForm
          open={signupModal}
          handleClose={() => setSignupModal(false)}
        />
      )}
    </>
  );
};

export default Header;
