import "./home.css";
import Pricing from "./sections/Pricing";
import HeroBanner from "./sections/HeroBanner";
import Features from "./sections/Features";
import WhyChoose from "./sections/whyChoose";
import Testimonials from "./sections/Testimonials";
import CallToAction from "./sections/CallToAction";

const Sections = () => {
  return (
    <>
      <div className="body-wrapper">
        <HeroBanner />
        <Features />
        <WhyChoose />
        <Pricing />
        <CallToAction />
        <Testimonials />
      </div>
    </>
  );
};

export default Sections;
