import * as React from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid2";
import Button from "../../components/Button";

export default function ResponsiveDialog({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [Industry, setIndustry] = React.useState("");

  const handleChange = (event) => {
    setIndustry(event.target.value);
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="seeDemo-popup"
      >
        <DialogContent sx={{ p: 0 }}>
          <Grid container spacing={0}>
            <Grid item size={6}>
              <div className="left-info-panel-dialog">
                <Typography variant="h2" mb={2}>
                  Watch TaskBoard in action.
                </Typography>
                <Typography className="lead-text" variant="body1" mb={3}>
                  View our demonstration to learn how to:
                </Typography>
                <ul>
                  <li>
                    Link tasks to objectives to monitor progress more
                    effectively.
                  </li>
                  <li>
                    Bring cross-functional teams together and automate
                    processes.
                  </li>
                  <li>Boost productivity and simplify your IT stack.</li>
                </ul>
              </div>
            </Grid>
            <Grid item size={6} p={6} alignContent="center">
              <div className="seeDemo-form">
                <Typography variant="h6" mb={3}>
                  To get started, please fill the below information.
                </Typography>
                <Stack spacing={2}>
                  <TextField
                    required
                    margin="dense"
                    id="cname"
                    name="CompanyName"
                    label="Company Name"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Industry Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Industry}
                      label="Industry Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"ItSoftware"}>IT Software</MenuItem>
                      <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                      <MenuItem value={"Finance"}>Finance</MenuItem>
                    </Select>
                  </FormControl>
                  <DialogActions style={{ padding: 0, marginTop: 28 }}>
                    <Button
                      buttonText={"Cancel"}
                      className={"custom-btn-outline"}
                      handleClick={handleClose}
                    />
                    <Button
                      buttonText={"Submit"}
                      className={"custom-btn"}
                      handleClick={handleClose}
                    />
                  </DialogActions>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
