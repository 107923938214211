import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import NoFeesIcon from "../../../assets/svg/no-fees.svg";
import ClientAccessIcon from "../../../assets/svg/client-access.svg";
import FullAccessIcon from "../../../assets/svg/full-access.svg";
import PredictableIcon from "../../../assets/svg/predictable-pricing.svg";

const featureData = [
  {
    icon: NoFeesIcon,
    title: "No per user fee",
    description:
      "Include everyone in your organization without additional fees, supporting broad collaboration.",
    altText: "No Fees Icon",
  },
  {
    icon: ClientAccessIcon,
    title: "Client & Contractor Access",
    description:
      "Add external users like clients and contractors to projects without increasing costs.",
    altText: "Client Access Icon",
  },
  {
    icon: FullAccessIcon,
    title: "Full Access for All Features",
    description:
      "No feature limits by tier, so all users have access to the tools they need for success.",
    altText: "Full Access Icon",
  },
  {
    icon: PredictableIcon,
    title: "Predictable Pricing",
    description:
      "One flat rate ensures easier budgeting and eliminates hidden costs.",
    altText: "Predictable Icon",
  },
];

const Features = () => {
  return (
    <Box component="section" className="features" pb={6}>
      <Container>
        <Grid container spacing={2}>
          {featureData.map((feature, index) => (
            <Grid key={index} item size={{ xs: 12, md: 3 }} px={1}>
              <div className="features-grid">
                <img src={feature.icon} alt={feature.altText} />
                <Typography variant="h3">{feature.title}</Typography>
                <Typography variant="body1">{feature.description}</Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
