import React from "react";
import { Container, Box, Stack, Typography } from "@mui/material";
import Button from "../../../components/Button";
import SeeDemo from "../../../components/dialog/SeeDemo";
import SignupForm from "../../../components/dialog/Signup";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const HeroBanner = () => {
  const [open, setOpen] = React.useState(false);
  const [signupModal, setSignupModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box component="section" className="hero-banner">
        <Container>
          <div className="hero-banner-content">
            <Typography variant="h1">
              Project Management, <span>Simplified</span> for Teams of Any Size
            </Typography>
            <Typography variant="body1">
              All the powerful features of top project management tools – at one
              fixed price, with unlimited users.
            </Typography>

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                buttonText={"Get Started"}
                className={"custom-btn"}
                handleClick={() => setSignupModal(true)}
              >
                <ArrowOutwardIcon />
              </Button>
              <Button
                buttonText={"See a Demo"}
                className={"custom-btn-outline"}
                handleClick={handleClickOpen}
              />
            </Stack>
          </div>
        </Container>
      </Box>

      {open && <SeeDemo open={open} handleClose={handleClose} />}
      {signupModal && (
        <SignupForm
          open={signupModal}
          handleClose={() => setSignupModal(false)}
        />
      )}
    </>
  );
};
export default HeroBanner;
