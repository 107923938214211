import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Slider from "react-slick";
import DummyProfile from "./../../../assets/images/blank-profile-picture.jpg";

const slideToShowPerPage = 3;
const slidesToScroll = 1;

const Testimonials = () => {
  // Array of testimonials
  const testimonials = [
    {
      name: "Alex M.",
      title: "Head of Operations",
      feedback:
        "TaskBoard lets us grow without the surprise fees! Our entire team has access, and we never have to worry about outgrowing our project management budget.",
    },
    {
      name: "Jamie S.",
      title: "Project Manager",
      feedback:
        "The fixed pricing model is a game-changer. We can bring in clients and contractors for each project without worrying about extra charges. It’s the perfect tool for a collaborative environment.",
    },
    {
      name: "Sam L.",
      title: "Product Owner",
      feedback:
        "TaskBoard has streamlined our processes and keeps everyone on the same page with budget-friendly pricing. Highly recommend for growing teams!",
    },
  ];

  const settings = {
    dots: testimonials.length > slideToShowPerPage ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: slideToShowPerPage,
    slidesToScroll: slidesToScroll,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box component="section" className="testimonials" py={6}>
      <Container>
        <Typography variant="h2" style={{ textAlign: "center" }} mb={6}>
          Trusted by Thousands of <br /> Happy Customers
        </Typography>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4} px={1}>
                  <div className="testimonial-grid">
                    <div className="testimonial-header">
                      <img src={DummyProfile} alt="Profile Image" />
                      <div>
                        <Typography variant="h3">{testimonial.name}</Typography>
                        <Typography variant="caption">
                          {testimonial.title}
                        </Typography>
                      </div>
                    </div>
                    <Typography variant="body1">
                      {testimonial.feedback}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Testimonials;
