import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Typography,
  Stack,
  Box,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

const steps = ["Company Info", "Select a Plan", "Checkout"];

export default function ResponsiveDialog({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => step === 1;
  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  // Function to return the content for each step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6" mb={0} style={{ fontSize: 20 }}>
              Company Information
            </Typography>
            <Typography variant="body1" mb={3} className="lead-text">
              Please enter your details to help us serve you better.
            </Typography>
            <Stack spacing={2}>
              <TextField
                required
                id="name"
                name="cname"
                label="Company Name"
                type="text"
                fullWidth
              />
              <TextField
                required
                id="name"
                name="cdomain"
                label="Company Domain"
                type="text"
                fullWidth
              />
              <TextField
                required
                id="name"
                name="cemail"
                label="Company Email"
                type="email"
                fullWidth
              />
              <FormControl>
                <FormLabel>
                  <strong>Logo</strong>
                </FormLabel>
                <TextField
                  required
                  margin="dense"
                  id="name"
                  name="clogo"
                  type="file"
                  fullWidth
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  <strong>Fav Icon</strong>
                </FormLabel>
                <TextField
                  required
                  margin="dense"
                  id="name"
                  name="cFav"
                  type="file"
                  fullWidth
                />
              </FormControl>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" mb={0}>
              Select a Plan
            </Typography>
            <Typography variant="body1" mb={3} className="lead-text">
              Pick the perfect plan tailored to your preferences and unlock
              exclusive features.
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <strong>Subscription Type</strong>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Monthly"
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="Monthly"
                    control={<Radio />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="Yearly"
                    control={<Radio />}
                    label="Yearly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <strong>Billing Address</strong>
                </FormLabel>
                <Grid container spacing={2} mt={2}>
                  <Grid item size={6}>
                    <TextField
                      required
                      id="name"
                      name="fname"
                      label="First Name"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      required
                      id="name"
                      name="lname"
                      label="Last Name"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={12}>
                    <TextField
                      required
                      id="name"
                      name="Address"
                      label="Address"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      required
                      id="name"
                      name="City"
                      label="City"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      required
                      id="name"
                      name="State"
                      label="State"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      required
                      id="name"
                      name="zipcode"
                      label="Zip Code"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Stack>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" mb={2}>
              Checkout
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <strong>Card Details</strong>
                </FormLabel>
                <Grid container spacing={2} mt={1}>
                  <Grid item size={12}>
                    <TextField
                      required
                      id="name"
                      name="cnumber"
                      label="Card number"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={8}>
                    <TextField
                      required
                      id="name"
                      name="ExpDate"
                      label="Expiration date"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      required
                      id="name"
                      name="card_cvv"
                      label="CVV"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item size={12}>
                    <TextField
                      required
                      id="name"
                      name="card_name"
                      label="Name on Card"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <strong>Promo Code</strong>
                </FormLabel>
                <Grid container spacing={2} mt={1}>
                  <Grid item size={12}>
                    <TextField
                      required
                      id="name"
                      name="p_code"
                      label="Enter Code"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Stack>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            maxWidth: 540,
            width: "100%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            fontSize: 20,
            fontWeight: 700,
            backgroundColor: "#F2F6FE",
          }}
        >
          Signup: Join Us Today
        </DialogTitle>
        <DialogContent sx={{ p: 5, paddingTop: "40px !Important" }}>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 6, mb: 1, textAlign: "center" }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    size="large"
                  >
                    finished
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 4,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    size="large"
                  >
                    Back
                  </Button>
                  <Button variant="contained" onClick={handleNext} size="large">
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
