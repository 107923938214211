import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import Header from "../src/components/header/Header.js";
import { Sections } from "./pages/home";
import Footer from "../src/components/footer/Footer.js";
import "./assets/style.css";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Sections />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
