import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BoardImage from "../../../assets/images/board.png";

const whyChooseData = [
  {
    image: BoardImage,
    title: "No per user fee",
    content: [
      "One Price No matter how big your team grows, your cost stays the same. TaskBoard believes collaboration shouldn’t come at a premium. With a single, fixed price, add as many team members, clients, and contractors as you need—no additional fees, ever.",
      "Enjoy complete freedom to collaborate across departments, bring in clients for transparent project updates, and scale your team without worrying about escalating costs.",
    ],
  },
  {
    image: BoardImage,
    title: "Complete Access for Every User, All the Features, All the Time.",
    content: [
      "Unlike other tools that charge more for advanced features, TaskBoard gives your team access to all tools and capabilities, no matter your team size. Manage tasks, track progress, set deadlines, and collaborate without restrictions.",
      "Empower every team member to use TaskBoard’s full suite, from managers to collaborators, without the need to upgrade to higher tiers.",
    ],
  },
  {
    image: BoardImage,
    title: "Predictable Pricing, Easy Budgeting Say goodbye to surprise costs.",
    content: [
      "With TaskBoard’s fixed per-company pricing, know exactly what you’re paying each month. Plan confidently and allocate your budget toward growth initiatives instead of unpredictable software fees.",
      "You can have your entire team in one place without juggling per-user fees. TaskBoard makes collaboration easy, giving you full control over projects and operations from a single dashboard.",
    ],
  },
];

const WhyChoose = () => {
  return (
    <Box component="section" className="why-choose-us" py={6}>
      <Container>
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Why Choose TaskBoard?
        </Typography>

        {whyChooseData.map((section, index) => (
          <Grid
            container
            spacing={8}
            alignItems="center"
            key={index}
            direction={index % 2 == 0 ? "row" : "row-reverse"}
            mt={6}
          >
            <Grid item size={{ xs: 12, md: 6 }}>
              <div className="why-choose-image">
                <img src={section.image} alt={section.title} />
              </div>
            </Grid>
            <Grid item size={{ xs: 12, md: 6 }}>
              <div className="why-choose-content">
                <Typography variant="h3" mb={2}>
                  {section.title}
                </Typography>
                {section.content.map((paragraph, idx) => (
                  <Typography variant="body1" key={idx} mb={2}>
                    {paragraph}
                  </Typography>
                ))}
              </div>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Box>
  );
};

export default WhyChoose;
