import React from "react";
import { Container, Box, Typography } from "@mui/material";

const Pricing = () => {
  const features = [
    "No Per User Fee",
    "Kanban Boards",
    "Scrum Boards",
    "Gantt Charts",
    "Custom Workflows",
    "Task Automation",
    "Time Tracking",
    "Real-time Reporting",
    "Project Timelines",
    "Customizable Dashboards",
    "Integrations",
    "Task Assignments",
    "Bug Tracking",
    "Multi-project Management",
    "Unlimited Users",
  ];

  const products = [
    {
      name: "TaskBoard",
      price: "$0.00",
      fixedCost: "$49.99 ($499.99 per year)",
      features: [
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
      ],
    },
    {
      name: "Jira",
      price: "Free (10 users), $7.75 - $15.25",
      fixedCost: "NA",
      features: [
        false,
        true,
        true,
        false,
        true,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
      ],
    },
    {
      name: "Asana",
      price: "Free (10 Users), $10.99 - $24.99",
      fixedCost: "NA",
      features: [
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        false,
      ],
    },
    {
      name: "Trello",
      price: "Free (10 Users) $5 - $10",
      fixedCost: "NA",
      features: [
        false,
        true,
        false,
        false,
        false,
        "limited",
        true,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
      ],
    },
    {
      name: "Monday.com",
      price: "Free (2 users), $8 - $10",
      fixedCost: "NA",
      features: [
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
        true,
        false,
      ],
    },
    {
      name: "Azure DevOps",
      price: "Basic: $6, Advanced varies",
      fixedCost: "NA",
      features: [
        false,
        true,
        true,
        false,
        true,
        false,
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
      ],
    },
    {
      name: "Service Now",
      price: "Enterprise pricing only",
      fixedCost: "NA",
      features: [
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
      ],
    },
    {
      name: "Zoho Projects",
      price: "Free, $5 - $10",
      fixedCost: "NA",
      features: [
        false,
        true,
        false,
        true,
        true,
        false,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        false,
      ],
    },
  ];

  return (
    <>
      <Box component="section" className="pricing" id="pricing" py={6}>
        <Container>
          <Typography variant="h2" style={{ textAlign: "center" }} mb={6}>
            All the Tools You Need to Get Work Done
          </Typography>
          <div className="feature-comparison">
            <div className="feature-header-row">
              <div className="cell feature-title">Feature</div>
              {products.map((product, index) => (
                <div key={index} className="cell product-title">
                  {product.name}
                </div>
              ))}
            </div>
            {features.map((feature, index) => (
              <div key={index} className="feature-td-row row">
                <div className="cell feature">{feature}</div>
                {products.map((product, productIndex) => (
                  <div key={productIndex} className="cell feature-check">
                    {product.features[index] === true
                      ? "✔️"
                      : product.features[index] || ""}
                  </div>
                ))}
              </div>
            ))}
            <div className="feature-td-row row">
              <div className="cell feature">Pricing (per user/month)</div>
              {products.map((product, index) => (
                <div key={index} className="cell feature-check">
                  {product.price}
                </div>
              ))}
            </div>
            <div className="feature-td-row row ">
              <div className="cell feature">Fixed Monthly Cost</div>
              {products.map((product, index) => (
                <div key={index} className="cell feature-check">
                  {product.fixedCost}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};
export default Pricing;
