import React from "react";
import { Container, Stack, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Button from "../../components/Button";
import "./footer.css";
import SeeDemo from "../dialog/SeeDemo";
import SignupForm from "../dialog/Signup";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const [signupModal, setSignupModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <footer className="footer" id="footer">
        <Container>
          <div className="top-header">
            <Typography variant="h2" style={{ textAlign: "center" }} mb={4}>
              Get Started with TaskBoard today, <br />A world of productive team
              work awaits.
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                buttonText={"Get Started"}
                className={"custom-btn"}
                handleClick={() => setSignupModal(true)}
              >
                <ArrowOutwardIcon />
              </Button>
              <Button
                buttonText={"See a Demo"}
                className={"custom-btn-outline"}
                handleClick={handleClickOpen}
              />
            </Stack>
          </div>
          <div className="bottom-footer">
            <p className="copyright">©2024 TaskBoard. All rights reserved.</p>
            <ul className="social-media">
              <li>
                <Link href="#" title="Facebook">
                  <FacebookIcon />
                </Link>
              </li>
              <li>
                <Link href="#" title="Twitter">
                  <XIcon />
                </Link>
              </li>
              <li>
                <Link href="#" title="LinkedIn">
                  <LinkedInIcon />
                </Link>
              </li>
              <li>
                <Link href="#" title="Instagram">
                  <InstagramIcon />
                </Link>
              </li>
            </ul>
            <ul className="footer-nav">
              <li>
                <Link href="#">Privacy & Policy</Link>
              </li>
              <li>
                <Link href="#">Terms & Condition</Link>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
      {open && <SeeDemo open={open} handleClose={handleClose} />}
      {signupModal && (
        <SignupForm
          open={signupModal}
          handleClose={() => setSignupModal(false)}
        />
      )}
    </>
  );
};
export default Footer;
