import React from "react";

const Button = ({ buttonText = "", handleClick, className, children }) => {
  return (
    <>
      <button
        type="button"
        className={`btn ${className}`}
        onClick={handleClick ? handleClick : undefined}
      >
        <span>
          {buttonText}
          {children}
        </span>
      </button>
    </>
  );
};

export default Button;
