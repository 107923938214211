import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Button from "../../../components/Button";
import SignupForm from "../../../components/dialog/Signup";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const CallToAction = () => {
  const [open, setOpen] = React.useState(false);
  const [signupModal, setSignupModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box component="section" className="call-to-action">
        <Container>
          <div className="call-action-content">
            <Typography variant="body1">
              Experience TaskBoard’s Freedom to Scale Say goodbye to per-user
              costs and hello to a simpler, more predictable way to manage
              projects. Join TaskBoard today and get powerful project management
              at one fixed price.
            </Typography>
            <Button
              buttonText={"Start Your Free Trial"}
              className={"custom-btn"}
              handleClick={() => setSignupModal(true)}
            >
              <ArrowOutwardIcon />
            </Button>
          </div>
        </Container>
      </Box>
      {signupModal && (
        <SignupForm
          open={signupModal}
          handleClose={() => setSignupModal(false)}
        />
      )}
    </>
  );
};
export default CallToAction;
